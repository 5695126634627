import * as consts from '../../../consts/consts';

import { FormGroup, Input, Label } from 'reactstrap';
import { FormattedMessage, useIntl } from 'react-intl';
import React, {
  Fragment,
  useCallback,
  useEffect,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { capitalize, getUniqueHtmlId } from '../../../utils/util/formatter';

import ElasticsearchAPI from '../../../utils/api/ElasticsearchAPI';
import PropTypes from 'prop-types';
import ReactTags from '../../../vendor/react-tags-6.0/lib/ReactTags';
import UncontrolledPopover from 'components/SafeUncontrolledPopover';
import { connect } from 'react-redux';
import { debounce } from 'lodash';
import fuzzysort from 'fuzzysort';
import { isEnabled } from '../../../utils/util/util';
import { renderSuggestion } from './ValidatedAutosuggest';
import { toast } from 'react-toastify';
import { useAuth0 } from '@auth0/auth0-react';
import { withSelfRecoveryErrorBoundary } from 'views/Error/ErrorBoundary';

const MAX_RECOMMENDATIONS_LENGTH = 7;

// per research from QA, the breaking point between legit and "long sentences"
// based on the data of skills analyzed (as of August 2022) for input is about
// 40 characters. Also, 4 spaces, even if shorter, is another sign that this
// is a sentence instead of a valid skill.
const MAX_LENGTH_OF_INPUT = 40;
const MAX_SPACES_IN_INPUT = 4;

const ReactTagsWithErrorBoundary = withSelfRecoveryErrorBoundary(ReactTags);

const ReactTagsInput = (props) => {
  const { formatMessage } = useIntl();
  const [suggestions, setSuggestions] = useState(
    props.suggestions ? props.suggestions : []
  );
  const [defaultOption, setDefaultOption] = useState(null);
  // NOTE: needs to be undefined instead of null so no type is passed
  // into backend when type is not relevant
  const [defaultOptionType, setDefaultOptionType] = useState(undefined);
  const [tags, setTags] = useState(props.value);
  const [elasticsearchSuggestions, setElasticsearchSuggestions] = useState([]);
  const [defaultOptionIndex, setDefaultOptionIndex] = useState(undefined);
  const propsPlaceholder =
    props.placeholder ??
    formatMessage({
      id: 'app.views.widgets.inputs.react_tags_input.placeholder',
      defaultMessage: 'Short keywords only',
    });
  const [placeholderText, setPlaceholderText] = useState(
    props.value?.length > 0 && !props.showPlaceholderAfterFirstTag
      ? ''
      : propsPlaceholder
  );
  const { user } = useAuth0();
  const [isInputTooLong, setIsInputTooLong] = useState(false);
  const [hasFocus, setHasFocus] = useState(false);
  const [inputQuery, setInputQuery] = useState('');

  const defaultInputRef = useRef();
  const [inputRef, setInputRef] = useState(
    props.innerRef ? props.innerRef : defaultInputRef
  );

  const [validationMessage, setValidationMessage] = useState(null);
  const propsOnValidChange = props.onValidChange;
  const propsRequired = props.required;
  const propsMaxTags = props.maxTags;
  const suggestionsTransform = props.suggestionsTransform;
  const propsCallback = props.callback;
  const propsOnBeforeChange = props.onBeforeChange;
  const propsOnInputChange = props.onInputChange;
  const currentOrgId = props.currentOrganization?.id;
  const esOptions = props.elasticsearchOptions;
  const userSub = user?.sub;
  const validationFunction = props.validationFunction;
  const renderCreateOption = props.renderCreateOption;
  const propsExcludeList = props.excludeList;
  const propsExcludeListErrorMessage =
    props.excludeListErrorMessage ??
    formatMessage({
      id: 'app.views.widgets.inputs.react_tags_input.is_already_on_the_list',
      defaultMessage: 'is already on the list.',
    });
  const propsExcludeListErrorMessageFunction =
    props.excludeListErrorMessageFunction;
  const propsTagsAreEqual = props.tagsAreEqual;
  const propsExcludeTagMatchFunction = props.excludeTagMatchFunction;
  const propsIsDisabled = props.disabled;
  const intl = useIntl();

  useEffect(() => {
    // if there are any invalid tags, require their removal first
    if (tags?.length > 0 && validationFunction) {
      const invalidTags = tags.filter((t) => !validationFunction(t.name, true));
      if (invalidTags.length > 0) {
        setValidationMessage(
          formatMessage(
            {
              id: 'app.views.widgets.inputs.react_tags_input.remove_invalid_tags',
              defaultMessage: 'Remove invalid tags: {tags}',
            },
            {
              tags: invalidTags.map((t) => t.name).join(', '),
            }
          )
        );
        return;
      }
    }

    if (propsRequired) {
      const isValid = tags?.length > 0;
      setValidationMessage(
        isValid
          ? null
          : formatMessage({
              id: 'app.views.widgets.inputs.react_tags_input.required',
              defaultMessage: 'This field is required.',
            })
      );
    } else {
      setValidationMessage(null);
    }
  }, [propsRequired, tags, validationFunction, formatMessage]);

  useEffect(() => {
    if (propsOnValidChange) {
      propsOnValidChange(validationMessage);
    }
  }, [validationMessage, propsOnValidChange]);

  useEffect(() => {
    setInputRef(props.innerRef ? props.innerRef : defaultInputRef);
  }, [props.innerRef]);

  const tagComponent = useCallback(
    ({ tag, removeButtonText, onDelete }) => {
      return renderSuggestion(
        intl,
        tag,
        '',
        true,
        'xxs',
        false,
        removeButtonText,
        onDelete,
        true,
        false,
        false,
        validationFunction,
        propsIsDisabled
      );
    },
    [validationFunction, propsIsDisabled, intl]
  );

  // calculate placeholder in useLayoutEffect as size of input is computed based on
  // length of this field
  useLayoutEffect(() => {
    // update ui if tag length changes to ensure placeholder width gets recalculated
    setPlaceholderText(
      tags?.length > 0 && !props.showPlaceholderAfterFirstTag
        ? ''
        : propsPlaceholder
    );
  }, [props.showPlaceholderAfterFirstTag, propsPlaceholder, tags]);

  useEffect(() => {
    setTags(props.value);
  }, [props.value]);

  // Gets exact match from suggestions (only needed for non elastic search querying)
  const exactSuggestions = useMemo(() => {
    if (!props.suggestions) {
      return [];
    }

    return fuzzysort
      .go(inputQuery, props.suggestions, {
        key: 'name',
      })
      .map((s) => s.obj);
  }, [inputQuery, props.suggestions]);

  useEffect(() => {
    setSuggestions(
      exactSuggestions
        ? exactSuggestions.concat(elasticsearchSuggestions)
        : elasticsearchSuggestions
    );
  }, [exactSuggestions, elasticsearchSuggestions]);

  const suggestionComponentFunction = useCallback(
    ({ item, query }) => {
      // show dropdown items in size 'xs' for larger selection size, Fitt's law (although tags are size 'xxs')
      return renderSuggestion(intl, item, query, true, 'xs');
    },
    [intl]
  );

  const onDelete = useCallback(
    (i) => {
      // Do not propagate if the tag list is already empty
      if (i === -1) {
        return;
      }
      // cancel if disallowed
      if (propsOnBeforeChange && !propsOnBeforeChange('delete', tags[i], tags))
        return;

      const newTags = tags.slice(0);

      // TODO: find way to prepend input field with newTags[i] to be more forgiving of deletion,
      // i.e. if someone doesn't realize delete removes the entire tag

      newTags.splice(i, 1);
      setTags(newTags);

      if (typeof propsCallback !== 'undefined') {
        propsCallback(newTags);
      }
    },
    [propsCallback, propsOnBeforeChange, tags]
  );

  const propsClearTagsAfterCallback = props.clearTagsAfterCallback;
  const onAddition = useCallback(
    (tag) => {
      // cancel if disallowed
      if (propsOnBeforeChange && !propsOnBeforeChange('add', tag, tags)) return;

      // if they select something long on the list,
      // the notice should go away
      setIsInputTooLong(false);

      // if tag is default option, apply index if it is set
      // (this is for, example, when we have buttons to indicate
      // whether the new item is a skill or credential, or for
      // a skill of a specific type, e.g. behavior)
      if (
        (defaultOptionIndex || defaultOptionType) &&
        defaultOption &&
        defaultOption === tag
      ) {
        tag = {
          name: tag.name,
          type: defaultOptionType, // needed for skill types (e.g. create behavior, not experience)
          _index: defaultOptionIndex,
          object: {
            name: tag.name,
            type: defaultOptionType, // needed for skill types (e.g. create behavior, not experience)
            _index: defaultOptionIndex,
          },
        };
      }

      // strip whitespace from ends of tag
      tag.name = tag.name.trim();

      // tag either has object or not; equality should be tested accordingly
      // if tag object is on exclude list, ignore it and tell the user
      if (
        (propsExcludeList &&
          propsExcludeList.findIndex((excludeTag) =>
            propsTagsAreEqual(excludeTag, propsExcludeTagMatchFunction(tag))
          ) !== -1) ||
        (tags &&
          tags
            .map(propsExcludeTagMatchFunction)
            .findIndex((existingTag) =>
              propsTagsAreEqual(existingTag, propsExcludeTagMatchFunction(tag))
            ) !== -1)
      ) {
        if (propsExcludeListErrorMessageFunction) {
          toast.info(propsExcludeListErrorMessageFunction(tag));
        } else {
          toast.info(capitalize(tag.name) + ' ' + propsExcludeListErrorMessage);
        }
        return;
      }

      const newTags = [...tags, tag];

      if (!propsClearTagsAfterCallback) {
        setTags(newTags);
      }

      if (typeof propsCallback !== 'undefined') {
        propsCallback(newTags);
      }
    },
    [
      defaultOptionIndex,
      defaultOptionType,
      defaultOption,
      propsExcludeList,
      tags,
      propsExcludeTagMatchFunction,
      propsClearTagsAfterCallback,
      propsCallback,
      propsOnBeforeChange,
      propsTagsAreEqual,
      propsExcludeListErrorMessageFunction,
      propsExcludeListErrorMessage,
    ]
  );

  const onInputChange = useCallback(
    (query) => {
      // if this is just spaces, ignore it
      if (!query?.trim()) {
        if (props.deliverEmptyInputChange && propsOnInputChange) {
          propsOnInputChange(query);
        }
        return;
      }

      if (props.disableLengthCheck) {
        setIsInputTooLong(false);
      } else {
        const spaceCount = (query.trim().match(/ /g) || []).length;
        const tooLong =
          query.length > MAX_LENGTH_OF_INPUT ||
          spaceCount >= MAX_SPACES_IN_INPUT;

        setIsInputTooLong(tooLong);

        // do not allow the creation of these long skills at all
        // (but still allow selecting them in a dropdown, e.g.
        // for long acronyms w/descriptions that are curated by us)
        if (tooLong) {
          return;
        }
      }

      if (esOptions && query) {
        let q = esOptions.getQuery
          ? esOptions.getQuery(query)
          : query
          ? { query: query }
          : {};

        // restrict results to active employees
        if (isEnabled(props.features, consts.FLAGS.HIDE_FORMER_EMPLOYEES)) {
          q.person_statuses = ['A', 'L'];
        }

        ElasticsearchAPI.search(
          // only cache if no query provided (so we don't take up too much
          // memory story search results as searches are done)
          q && Object.keys(q).length > 0 ? undefined : userSub,
          props.currentProxyPerson,
          currentOrgId,
          esOptions.url,
          q,
          (hits) => {
            setElasticsearchSuggestions(
              hits.map(
                esOptions.mapFunction
                  ? esOptions.mapFunction
                  : ElasticsearchAPI.defaultSelectorMapFunction
              )
            );
          },
          (error) => {
            console.error('ReactTagsInput error: ' + JSON.stringify(error));
            setElasticsearchSuggestions([]);
          }
        );
      }

      if (query) {
        setDefaultOption(
          renderCreateOption(query, setDefaultOptionIndex, setDefaultOptionType)
        );
      } else {
        setDefaultOption(null);
      }

      setInputQuery(query);

      if (propsOnInputChange) {
        return propsOnInputChange(query);
      }
    },
    [
      props.disableLengthCheck,
      props.deliverEmptyInputChange,
      props.currentProxyPerson,
      esOptions,
      propsOnInputChange,
      userSub,
      currentOrgId,
      renderCreateOption,
      props.features,
    ]
  );

  const debouncedOnInputChange = useMemo(
    () =>
      debounce(onInputChange, 250, {
        leading: false,
      }),
    [onInputChange]
  );

  const inputAttributes = useMemo(
    () => ({
      spellCheck: false,
      autoFocus: props.autoFocus,
      autoComplete: props.autoComplete ? props.autoComplete : 'off', // so extensions like 1password don't show up
      name: props.name,
      readOnly: tags.length >= propsMaxTags,
    }),
    [props.autoComplete, props.autoFocus, props.name, propsMaxTags, tags.length]
  );

  const reactTagsSuggestions = useMemo(() => {
    let finalSuggestions = suggestionsTransform
      ? suggestionsTransform(suggestions)
      : suggestions;

    if (props.allowNewOnlyIfNoSuggestions) {
      if (defaultOption && !(finalSuggestions?.length > 0)) {
        finalSuggestions = [defaultOption];
      }
    } else if (props.allowNew) {
      if (defaultOption) {
        // If allowDuplicateCreate is enabled, will allow creation of
        // a suggestion with a duplicate name. Otherwise, if default option
        // doesn't exact-match an existing suggestion, expose it in list
        if (
          finalSuggestions &&
          (props.allowDuplicateCreate ||
            finalSuggestions.findIndex((suggestion) =>
              propsTagsAreEqual(
                suggestion,
                propsExcludeTagMatchFunction(defaultOption)
              )
            ) === -1)
        ) {
          finalSuggestions = finalSuggestions.concat(defaultOption);
        }
      }
    }

    return finalSuggestions;
  }, [
    defaultOption,
    props.allowNew,
    props.allowNewOnlyIfNoSuggestions,
    props.allowDuplicateCreate,
    propsExcludeTagMatchFunction,
    propsTagsAreEqual,
    suggestions,
    suggestionsTransform,
  ]);

  const filteredRecommendations = useMemo(
    () =>
      props.recommendations
        ?.filter(
          (i) => tags && tags.findIndex((t) => propsTagsAreEqual(t, i)) === -1
        )
        .slice(0, MAX_RECOMMENDATIONS_LENGTH),
    [props.recommendations, propsTagsAreEqual, tags]
  );

  const addTag = useCallback(
    (tag) => inputRef.current?.addTag(tag),
    [inputRef]
  );

  // detect blurring of input while not registering false blurs when people
  // click on tags to remove them
  const BLUR_DELAY = 200; // milliseconds
  const [blurredAt, setBlurredAt] = useState();
  const onBlur = useCallback(() => {
    // if they select something long on the list,
    // the notice should go away
    setIsInputTooLong(false);

    if (props.onBlur) {
      setBlurredAt(
        setTimeout(() => {
          props.onBlur();
        }, BLUR_DELAY)
      );
    }
  }, [props]);
  const onFocus = useCallback(() => {
    if (blurredAt) {
      clearTimeout(blurredAt);
    }
    setBlurredAt(undefined);
  }, [blurredAt]);

  const reactTagsInputSpanId = useMemo(() => getUniqueHtmlId(), []);

  const inputElement = useMemo(
    () =>
      props.readOnly ? (
        <Input
          type="textarea"
          innerRef={inputRef}
          disabled
          value={tags.map((tag) => tag.name).join(', ')}
        />
      ) : (
        <>
          <span
            onFocus={() => setHasFocus(true)}
            onBlur={() => setHasFocus(false)}
            id={reactTagsInputSpanId}
          >
            <ReactTagsWithErrorBoundary
              classNames={
                props.className || props.inputClassName
                  ? {
                      root:
                        'react-tags' +
                        (props.className ? ' ' + props.className : ''),
                      // keep the rest the same
                      rootFocused: 'is-focused',
                      selected: 'react-tags__selected',
                      selectedTag: 'react-tags__selected-tag',
                      selectedTagName: 'react-tags__selected-tag-name',
                      search:
                        'react-tags__search' +
                        (props.inputClassName
                          ? ' ' + props.inputClassName
                          : '') +
                        (!(tags?.length > 0) ? ' is-empty' : ''),
                      searchWrapper: 'react-tags__search-wrapper',
                      searchInput: 'react-tags__search-input',
                      suggestions: 'react-tags__suggestions',
                      suggestionActive: 'is-active',
                      suggestionDisabled: 'is-disabled',
                      suggestionPrefix: 'react-tags__suggestion-prefix',
                    }
                  : undefined
              }
              inputAttributes={inputAttributes}
              ref={inputRef}
              tags={tags}
              onBlur={onBlur}
              onFocus={onFocus}
              onDelete={onDelete}
              onAddition={onAddition}
              delimiters={props.delimiters}
              // NOTE: allow new is set to FALSE ALWAYS because we add a custom "Create X" item
              // in the suggestion list if allowNew is true; this is to ensure that, for example,
              // typing a comma or tab selects the first item, but that there is still a way
              // to create something new in that case
              allowNew={false}
              addOnBlur={true}
              disabled={propsIsDisabled}
              placeholderText={placeholderText}
              suggestions={reactTagsSuggestions}
              tagComponent={props.useTagCards ? tagComponent : undefined}
              suggestionComponent={suggestionComponentFunction}
              suggestionsFilter={props.suggestionsFilter}
              noSuggestionsText={props.noSuggestionsText}
              onInput={debouncedOnInputChange}
              minQueryLength={props.minQueryLength}
              maxSuggestionsLength={props.maxSuggestionsLength}
              propagateOnClearInput={props.propagateOnClearInput}
              removeButtonText={formatMessage({
                id: 'app.views.widgets.inputs.react_tags_input.remove_tag',
                defaultMessage: 'Click to remove tag',
              })}
            />
          </span>
          {!props.disableLengthCheck && (
            <UncontrolledPopover
              // don't get in the way of dropdown options that show
              placement="bottom-end"
              isOpen={isInputTooLong && hasFocus}
              target={reactTagsInputSpanId}
            >
              <b>
                <FormattedMessage
                  id="app.views.widgets.inputs.react_tags_input.only_short_keywords"
                  defaultMessage="Only short keywords, please!"
                />
              </b>
              <br />
              <FormattedMessage
                id="app.views.widgets.inputs.react_tags_input.this_will_show_up"
                defaultMessage="
              This will show up as an option for others to select, so please
              keep it short and reusable.
            "
              />
            </UncontrolledPopover>
          )}
          {filteredRecommendations?.length > 0 && (
            <div className="small mt-2">
              <FormattedMessage
                id="app.views.widgets.inputs.react_tags_input.suggested"
                defaultMessage="Suggested:"
              />{' '}
              {filteredRecommendations.map((tag, index) => (
                <Fragment key={index}>
                  {index === 0 ? '' : ', '}
                  <span
                    className="btn-link"
                    role="button"
                    onClick={() => addTag(tag)}
                  >
                    {tag.name}
                  </span>
                </Fragment>
              ))}
            </div>
          )}
        </>
      ),
    [
      props.readOnly,
      props.className,
      props.inputClassName,
      props.delimiters,
      propsIsDisabled,
      props.useTagCards,
      props.suggestionsFilter,
      props.noSuggestionsText,
      props.minQueryLength,
      props.maxSuggestionsLength,
      props.disableLengthCheck,
      props.propagateOnClearInput,
      inputRef,
      tags,
      reactTagsInputSpanId,
      inputAttributes,
      onBlur,
      onFocus,
      onDelete,
      onAddition,
      placeholderText,
      reactTagsSuggestions,
      tagComponent,
      suggestionComponentFunction,
      debouncedOnInputChange,
      isInputTooLong,
      hasFocus,
      filteredRecommendations,
      addTag,
      formatMessage,
    ]
  );

  if (props.label) {
    return (
      <FormGroup>
        <Label>{props.label}</Label>
        {inputElement}
      </FormGroup>
    );
  } else {
    return inputElement;
  }
};

ReactTagsInput.defaultProps = {
  tagsAreEqual: (a, b) => {
    // match if object exists and ids match
    if (a?.object?.id && b?.object?.id && a.object.id === b.object.id) {
      return true;
    }

    // match if names match (case-insensitive)
    return a.name && b.name && a.name.toLowerCase() === b.name.toLowerCase();
  },
  excludeTagMatchFunction: (x) => x,
  renderCreateOption: (x) => ({ name: x }),
  allowDuplicateCreate: false,
  allowNew: true,
  allowNewOnlyIfNoSuggestions: false,
  disabled: false,
  value: [],
  suggestions: [],
  recommendations: [],
  delimiters: [';', ',', 'Enter', 'Tab'],
  useTagCards: false,
  excludeList: [],
  disableLengthCheck: false,
  maxTags: 1000,
  propagateOnClearInput: false,
};

ReactTagsInput.propTypes = {
  id: PropTypes.string,
  className: PropTypes.string,
  inputClassName: PropTypes.string,
  tagsAreEqual: PropTypes.func,
  name: PropTypes.string,
  autoFocus: PropTypes.bool,
  innerRef: PropTypes.object,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  value: PropTypes.arrayOf(PropTypes.object),
  allowDuplicateCreate: PropTypes.bool,
  allowNew: PropTypes.bool,
  allowNewOnlyIfNoSuggestions: PropTypes.bool,
  suggestions: PropTypes.arrayOf(PropTypes.object),
  suggestionsFilter: PropTypes.func,
  suggestionsTransform: PropTypes.func,
  recommendations: PropTypes.arrayOf(PropTypes.object),
  delimiters: PropTypes.arrayOf(PropTypes.string),
  placeholder: PropTypes.string,
  callback: PropTypes.func,
  noSuggestionsText: PropTypes.string,
  readOnly: PropTypes.bool,
  disabled: PropTypes.bool,
  onInputChange: PropTypes.func,
  onBlur: PropTypes.func,
  minQueryLength: PropTypes.number,
  elasticsearchOptions: PropTypes.object,
  currentOrganization: PropTypes.object.isRequired,
  useTagCards: PropTypes.bool,
  validationFunction: PropTypes.func,
  excludeList: PropTypes.arrayOf(PropTypes.object),
  excludeListErrorMessage: PropTypes.string,
  excludeListErrorMessageFunction: PropTypes.func,
  excludeTagMatchFunction: PropTypes.func,
  renderCreateOption: PropTypes.func,
  showPlaceholderAfterFirstTag: PropTypes.bool,
  autoComplete: PropTypes.string,
  clearTagsAfterCallback: PropTypes.bool,
  disableLengthCheck: PropTypes.bool,
  maxSuggestionsLength: PropTypes.number,
  maxTags: PropTypes.number,
  deliverEmptyInputChange: PropTypes.bool,
  propagateOnClearInput: PropTypes.bool,
  onBeforeChange: PropTypes.func, // (action: string, target: object, tags: object[]) => boolean
};

const mapStateToProps = (state) => {
  const { currentOrganization, currentProxyPerson, features } = state;

  return {
    currentOrganization,
    currentProxyPerson,
    features,
  };
};

export default connect(mapStateToProps)(React.memo(ReactTagsInput));
