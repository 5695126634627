// continuous feedback is split into 4 subfeatures, so we need
// to ensure that at least one of those subfeatures is enabled
// for the high level feature to be enabled at all
// NOTE: some clients may be define "{"continuous_feedback": {"enabled": true}}"
// explicitly without providing the below sub-items, so we default them all to enabled

import { Features, ReduxState } from '../../types';

import { useSelector } from 'react-redux';

export const resumesAreEnabled = (features: Features): boolean => {
  return !!features?.resumes?.enabled;
};

export const oneOnOnesAreEnabled = (features: Features): boolean => {
  return !!features?.one_on_ones?.enabled;
};

export const objectivesAreEnabled = (features: Features): boolean => {
  return !!features?.objectives?.enabled;
};

export const objectivesInDashboardAreEnabled = (
  features: Features
): boolean => {
  return !!(
    features?.objectives?.enabled && features?.objectives?.show_in_dashboard
  );
};

export const goalsAreEnabled = (features: Features): boolean => {
  return !!features?.goals?.enabled;
};

export const operatingManualsAreEnabled = (features: Features): boolean => {
  return !!features?.operating_manual?.enabled;
};

// unless they are explicitly set to false
export const atLeastOneContinuousFeedbackFeatureIsEnabled = (
  features?: Features
) => {
  return (
    features?.continuous_feedback?.enabled &&
    (features?.continuous_feedback?.recognition_enabled !== false ||
      features?.continuous_feedback?.private_notes_enabled !== false ||
      // note: we don't check requesting because if that one is true but
      // giving is false, there's no way to respond to requests, so we
      // don't want to enable the feature in that case
      features?.continuous_feedback?.giving_feedback_enabled !== false)
  );
};

export const activitiesInHomepageFeedIsEnabled = (features: Features) => {
  return (
    features?.activities?.enabled !== false &&
    features?.activities?.show_in_dashboard !== false
  );
};

export const continuousFeedbackInHomepageFeedIsEnabled = (
  features: Features
) => {
  return (
    atLeastOneContinuousFeedbackFeatureIsEnabled(features) &&
    features?.continuous_feedback?.show_in_dashboard !== false
  );
};

export const pulseChecksAreEnabled = (features: Features): boolean => {
  // note: "show_in_dashboard" for pulse checks refers to the feed, while
  // this refers to the feature existing at all (i.e. in the right sidebar
  // of the homepage where a person fills out the pulse check)
  return !!features?.pulse_checks?.enabled;
};

export const pulseChecksInHomepageFeedIsEnabled = (features: Features) => {
  return (
    features?.pulse_checks?.enabled !== false &&
    features?.pulse_checks?.show_in_dashboard !== false
  );
};

export const anyHomepageFeedItemsAreEnabled = (features: Features) => {
  return (
    activitiesInHomepageFeedIsEnabled(features) ||
    continuousFeedbackInHomepageFeedIsEnabled(features) ||
    pulseChecksInHomepageFeedIsEnabled(features)
  );
};

export const continuousFeedbackRecognitionIsEnabled = (
  features: Features
): boolean => {
  return !!(
    features?.continuous_feedback?.enabled &&
    features?.continuous_feedback?.recognition_enabled !== false
  );
};

export const continuousFeedbackPrivateNotesAreEnabled = (
  features: Features
): boolean => {
  return !!(
    features?.continuous_feedback?.enabled &&
    features?.continuous_feedback?.private_notes_enabled !== false
  );
};

export const continuousFeedbackGivingFeedbackIsEnabled = (
  features: Features
): boolean => {
  return !!(
    features?.continuous_feedback?.enabled &&
    features?.continuous_feedback?.giving_feedback_enabled !== false
  );
};

export const continuousFeedbackRequestsAreEnabled = (
  features: Features
): boolean => {
  // BOTH giving AND requesting feedback need to be enabled, because if
  // giving is disabled, there's no point in requesting as the request
  // cannot be fulfilled by the person receiving it
  return !!(
    features?.continuous_feedback?.enabled &&
    features?.continuous_feedback?.giving_feedback_enabled !== false &&
    features?.continuous_feedback?.feedback_requests_enabled !== false
  );
};

export const promotionPacketsAreEnabled = (features: Features): boolean => {
  // must be explicitly enabled
  return !!features?.promotion_packets?.enabled;
};

export const promotionPacketFeedbackRequestsAreEnabled = (
  features: Features
): boolean => {
  return (
    continuousFeedbackRequestsAreEnabled(features) &&
    promotionPacketsAreEnabled(features) &&
    // we default this to true; must be explicitly disabled
    features?.promotion_packets?.feedback_requests_enabled !== false
  );
};

export const continuousFeedbackRequestCustomQuestionsAreEnabled = (
  features: Features
): boolean => {
  return !!(
    continuousFeedbackRequestsAreEnabled(features) &&
    features?.continuous_feedback?.feedback_request_custom_questions_enabled !==
      false
  );
};

export const useFeatures = (): Features => {
  const features = useSelector<ReduxState, Features>((state) => state.features);
  return features;
};

export const isSkillsTalentInventoryEnabled = (features: Features): boolean => {
  return features?.skills_talent_inventory?.enabled ?? true;
};

export const isTalentMatricesEnabled = (features: Features): boolean => {
  return features?.talent_matrices?.enabled ?? false;
};

export const isExternalManagerRatingsEnabled = (
  features: Features
): boolean => {
  return features?.external_manager_ratings?.enabled ?? false;
};
