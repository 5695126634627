import { ModalBody, ModalHeader } from 'reactstrap';
import React, {
  FC,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';

import Modal from '../../../components/SafeModal';
import PropTypes from 'prop-types';
import { UNSAVED_CHANGES_PROMPT } from '../../../consts/consts';
import ValidatedForm from '../Forms/ValidatedForm';
import { useIntl } from 'react-intl';
import { useLocation } from 'react-router-dom';

const ModalEditor: FC<Props> = ({
  disableUnsavedChangesPrompt = false,
  cancelConfirmationText = UNSAVED_CHANGES_PROMPT,
  object = {},
  ...props
}) => {
  const { formatMessage } = useIntl();
  const focusRef = useRef(null);
  const [successData, setSuccessData] = useState(undefined);
  const [shouldFade, setShouldFade] = useState(true);
  const [anchorTrigger, setAnchorTrigger] = useState(props.anchorTrigger);

  const location = useLocation();
  const hash = location.hash;

  // pull out modal-related props
  const {
    callback,
    modalTitle,
    title,
    isOpen,
    onOpened,
    onClosed,
    toggle,
    setHasUnsavedChanges,
    footer,
    ...remainingProps
  } = props;

  const [localHasUnsavedChanges, setLocalHasUnsavedChanges] = useState(false);

  // when localHasUnsavedChanges changes,
  // callback to setHasUnsavedChanges prop if set
  useEffect(() => {
    if (setHasUnsavedChanges) {
      setHasUnsavedChanges(localHasUnsavedChanges);
    }
  }, [localHasUnsavedChanges, setHasUnsavedChanges]);

  useEffect(() => {
    // if there's success data, close the modal
    if (successData && toggle && isOpen) {
      // in cases where a modal is not set to render if isOpen is false,
      // this flag will be set, in which case we need to trigger the callback
      // before closing, else the isOpen will be false and this component
      // will cease to exist so the callback can't be triggered
      if (props.triggerCallbackBeforeClose) {
        callback(successData, null, null);

        // clear out success data to avoid an infinite loop
        setSuccessData(undefined);
      }

      toggle();
    }
  }, [successData, isOpen, toggle, props.triggerCallbackBeforeClose, callback]);

  const onClosedHandler = useCallback(() => {
    if (typeof successData !== 'undefined') {
      // now that modal is fully closed, submit this data to the callback
      // (this is necessary so modal-open doesn't persist in the case
      // of the callback using the router to go to a new page)
      // NOTE: we do not do this if triggerCallbackBeforeClose is set
      // as the callback will be triggered right before the close (see above)
      if (!props.triggerCallbackBeforeClose) {
        callback(successData, null, null);

        // clear out previous fields so it can close successfully a second time
        setSuccessData(undefined);
      }
    }

    setShouldFade(true);

    if (onClosed) {
      onClosed(typeof successData !== 'undefined');
    }
  }, [callback, onClosed, props.triggerCallbackBeforeClose, successData]);

  const onSubmitCallback = useCallback(
    (data, error, hardErrorMessage = null) => {
      // inline or keepOpenOnSuccess set should submit immediately; not inline, should set success data and wait
      // for it to close
      if (props.inline || props.keepOpenOnSuccess) {
        return callback(data, error, hardErrorMessage);
      } else {
        // errors shouldn't trigger callback as modal will stay open in this case
        if (!error && !hardErrorMessage) {
          // remove anchor trigger if set from current location so going back doesn't re-open modal
          // (without reloading anything from react router)
          if (hash) {
            window.history.replaceState(
              null,
              // @ts-expect-error
              null,
              location.pathname + location.search
            );
          }

          // allow quick fade-out when submitting
          // (NOTE: the callback above will be triggered in the useEffect for successData above)
          setShouldFade(false);
          setSuccessData(data);
        }
      }
    },
    [
      callback,
      hash,
      location.pathname,
      location.search,
      props.inline,
      props.keepOpenOnSuccess,
    ]
  );

  useEffect(() => {
    if (anchorTrigger && toggle && !isOpen && hash === '#' + anchorTrigger) {
      // anchorTrigger should automatically show the modal on load if the
      // current page has the anchor hash set to the trigger
      setAnchorTrigger(null);
      toggle();
    }
  }, [anchorTrigger, toggle, isOpen, hash]);

  const onOpenedHandler = useCallback(
    (e) => {
      if (focusRef?.current) {
        // @ts-expect-error
        focusRef.current.focus();
      }

      if (onOpened) {
        return onOpened(e);
      }
    },
    [onOpened]
  );

  const onCloseClicked = useCallback(() => {
    if (
      !localHasUnsavedChanges ||
      disableUnsavedChangesPrompt ||
      // @ts-expect-error
      window.confirm(cancelConfirmationText)
    ) {
      setLocalHasUnsavedChanges(false);
      toggle();
    }
  }, [
    localHasUnsavedChanges,
    cancelConfirmationText,
    disableUnsavedChangesPrompt,
    toggle,
  ]);

  const titleToUse = useMemo(
    () => (modalTitle ? modalTitle : title),
    [modalTitle, title]
  );

  const formBody = useMemo(
    () => (
      <ValidatedForm
        focusRef={focusRef}
        callback={onSubmitCallback}
        setHasUnsavedChanges={setLocalHasUnsavedChanges}
        object={object}
        disableUnsavedChangesPrompt={disableUnsavedChangesPrompt}
        // @ts-expect-error
        cancelConfirmationText={cancelConfirmationText}
        {...remainingProps}
      />
    ),
    [
      onSubmitCallback,
      object,
      disableUnsavedChangesPrompt,
      cancelConfirmationText,
      remainingProps,
    ]
  );

  const closeButton = useMemo(
    () => (
      <button
        className="btn-close"
        data-bs-dismiss="modal"
        aria-label={formatMessage({
          id: 'app.views.widgets.modals.modal_editor.aria_label.close',
          defaultMessage: 'Close',
        })}
        onClick={onCloseClicked}
      />
    ),
    [onCloseClicked, formatMessage]
  );

  const output = useMemo(() => {
    if (props.inline) {
      return formBody;
    } else {
      return (
        <Modal
          // prevent Esc from closing editor (to avoid issues e.g.
          // when someone escapes file dialog and presses twice)
          keyboard={false}
          // prevent hiding when clicking outside
          backdrop="static"
          // @ts-expect-error
          className={props.className}
          // autofocus must be false to enable inputs to have autoFocus on them directly
          autoFocus={false}
          // @ts-expect-error
          onOpened={onOpenedHandler}
          isOpen={isOpen}
          toggle={toggle}
          onClosed={onClosedHandler}
          fade={shouldFade}
          // @ts-expect-error
          size={props.size}
          // @ts-expect-error
          fullscreen={props.fullscreen}
        >
          <ModalHeader toggle={toggle} close={closeButton}>
            {props.renderModalHeader
              ? props.renderModalHeader(titleToUse)
              : titleToUse}
          </ModalHeader>
          <ModalBody>{formBody}</ModalBody>
          {footer}
        </Modal>
      );
    }
  }, [
    closeButton,
    formBody,
    isOpen,
    onClosedHandler,
    onOpenedHandler,
    props,
    shouldFade,
    titleToUse,
    toggle,
    footer,
  ]);

  return output;
};

const ModalEditor_propTypes = {
  hideSubmitButton: PropTypes.bool,
  isOpen: PropTypes.bool.isRequired,
  toggle: PropTypes.func.isRequired,
  callback: PropTypes.func.isRequired,
  triggerCallbackBeforeClose: PropTypes.bool,
  readOnly: PropTypes.bool,
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
  cancelConfirmationText: PropTypes.string,
  object: PropTypes.object,
  onClosed: PropTypes.func,
  onChange: PropTypes.func,
  inputs: PropTypes.arrayOf(PropTypes.object),
  onOpened: PropTypes.func,
  modalTitle: PropTypes.string,
  submitText: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  buttonClassName: PropTypes.string,
  buttonType: PropTypes.string,
  className: PropTypes.string,
  url: PropTypes.string,
  action: PropTypes.string,
  method: PropTypes.string,
  submitPromise: PropTypes.func,
  onValidate: PropTypes.func,
  disabled: PropTypes.bool,
  preSubmit: PropTypes.func,
  transformObjectBeforeSubmit: PropTypes.func,
  renderModalHeader: PropTypes.func,
  renderInputs: PropTypes.func,
  renderForm: PropTypes.func,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
  setHasUnsavedChanges: PropTypes.func,
  hasUnsavedChanges: PropTypes.bool,
  disableUnsavedChangesPrompt: PropTypes.bool,
  anchorTrigger: PropTypes.string,
  style: PropTypes.object,
  inline: PropTypes.bool,
  inlineSubmitButton: PropTypes.bool,
  keepOpenOnSuccess: PropTypes.bool,
  inForm: PropTypes.bool,
  size: PropTypes.string,
  fullscreen: PropTypes.string,
  noValidate: PropTypes.bool,
  footer: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
  onInputsChange: PropTypes.func,
  jsonPath: PropTypes.string,
  translationNamespace: PropTypes.string,
  translationVisibility: PropTypes.string,
};

type Props = PropTypes.InferProps<typeof ModalEditor_propTypes>;

export default React.memo(ModalEditor);
