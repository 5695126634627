import React, { FC, useMemo } from 'react';

import { FormattedMessage } from 'react-intl';
import Loading from '../Loading';
import Spinner from 'components/Spinner';
import { useBackgroundTask } from 'components/BackgroundTaskModal/hooks';

interface Props {
  backgroundTaskKey: string;
  onTaskCompleted?: (data: unknown) => void;
  onTaskError?: () => void;
}

const OutdatedDashboardBanner: FC<Props> = ({
  backgroundTaskKey,
  onTaskCompleted = () => {},
  onTaskError = () => {},
}) => {
  const taskDescriptor = useMemo(
    () => ({
      task_key: backgroundTaskKey,
      status: 'submitted',
    }),
    [backgroundTaskKey]
  );

  useBackgroundTask({
    taskDescriptor,
    onTaskCompleted: onTaskCompleted,
    onError: onTaskError,
    disabled: !backgroundTaskKey,
  });

  return (
    <Loading
      layout="banner"
      className="pb-4"
      message={
        <FormattedMessage
          id="app.views.widgets.dashboards.message.data_may_be_outdated_loading_latest_version_please_wait"
          defaultMessage="Data may be outdated. <loadingIndicator></loadingIndicator> Loading latest version. Please wait..."
          values={{
            loadingIndicator: () => (
              <Spinner spinnerStyle={{ height: '0.7rem', width: '0.7rem' }} />
            ),
          }}
        />
      }
    />
  );
};

export default OutdatedDashboardBanner;
