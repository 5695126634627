import * as consts from '../../consts/consts';

import {
  Card,
  CardBody,
  Col,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Progress,
  Row,
  UncontrolledDropdown,
  UncontrolledPopover,
} from 'reactstrap';
import {
  FEEDBACK_TYPE_ACTIONABLE,
  FEEDBACK_TYPE_NOTE,
  FEEDBACK_TYPE_RECOGNITION,
  FEEDBACK_TYPE_REQUEST,
} from '../../utils/models/Feedback';
import {
  FORMAT_AVATAR_ONLY,
  FORMAT_AVATAR_WITH_TITLE,
  getIconForFieldName,
} from '../Widgets/People/Filters/common';
import { FormattedMessage, useIntl } from 'react-intl';
import React, {
  FC,
  Fragment,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import {
  atLeastOneContinuousFeedbackFeatureIsEnabled,
  continuousFeedbackGivingFeedbackIsEnabled,
  continuousFeedbackPrivateNotesAreEnabled,
  continuousFeedbackRecognitionIsEnabled,
  continuousFeedbackRequestsAreEnabled,
} from '../../utils/util/features';
import { capitalize, loadOrRender } from '../../utils/util/formatter';
import {
  comparePeople,
  getDaysSince,
  getId,
  isEnabled,
} from '../../utils/util/util';

import ConfirmAPI from '../../utils/api/ConfirmAPI';
import EmptyState from '../Widgets/EmptyState';
import FilterablePeopleTable from '../Widgets/People/FilterablePeopleTable';
import { ICONS } from '../../consts/consts';
import Loading from '../Widgets/Loading';
import ModalFeedbackEditorButton from '../Feedback/ModalFeedbackEditorButton';
import PulseCheck from '../PulseChecks/PulseCheckCard';
import { connect } from 'react-redux';
import { getSchedules } from '../Schedule/Schedule';
import { useAuth0 } from '@auth0/auth0-react';
import { withRouter } from 'react-router-dom';
import { Features, Me, Organization, Person } from 'types';

const BELOW_MANAGER_FILTER_PREFIX = 'below_';
const HEATMAP_CELL_WIDTH = '6rem';

const RANGE_COLORS = ['#ffc226', '#ffdb80', '#ffedbf', '#fcfcfc'];

// health ranges for each column for alert, warning, ok, healthy
const STATUS_RANGES = [0, 1, 2, 3];
const NUM_BUCKETS = STATUS_RANGES.length;

const FEEDBACK_RANGES = [
  [90, undefined],
  [60, 89],
  [31, 59],
  [0, 30],
];
const OBJECTIVE_RANGES = [
  [90, undefined],
  [60, 89],
  [31, 59],
  [0, 30],
];
const ASPIRATION_RANGES = [
  [90, undefined],
  [60, 89],
  [31, 59],
  [0, 30],
];
const ONE_ON_ONE_RANGES = ['None', 'Every 2 months', 'Monthly', 'Weekly'];
const OPERATING_MANUAL_RANGES = [
  [0, 24],
  [25, 49],
  [50, 74],
  [75, 100],
];

const PULSE_CHECK_RANGES = [1, 2, 3, 4, 5];

const DottedItem = (props) => {
  return (
    <div
      style={{
        width: props.width,
        height: props.height,
        display: 'inline-flex',
        alignItems: 'center',
      }}
    >
      <div
        style={{
          background: props.color,
          borderRadius: '50%',
          width: '6px',
          height: '6px',
          marginRight: '8px',
          minWidth: '6px',
          minHeight: '6px',
        }}
      />
      <div className="text-center">
        <span className="small text-nowrap">{props.children}</span>
      </div>
    </div>
  );
};

const formatStatusFromValue = (value) => {
  let color = null;
  let content = null;

  if (value <= 0) {
    // @ts-expect-error
    color = RANGE_COLORS[0];
    // @ts-expect-error
    content = (
      <span>
        <FormattedMessage
          id="app.views.team.team_overview.status.very_low_alignment"
          defaultMessage="Very low alignment"
        />
      </span>
    );
  } else if (value === 1) {
    // @ts-expect-error
    color = RANGE_COLORS[1];
    // @ts-expect-error
    content = (
      <span>
        <FormattedMessage
          id="app.views.team.team_overview.status.low_alignment"
          defaultMessage="Low alignment"
        />
      </span>
    );
  } else if (value === 2) {
    // @ts-expect-error
    color = RANGE_COLORS[2];
    // @ts-expect-error
    content = (
      <span>
        <FormattedMessage
          id="app.views.team.team_overview.status.moderate_alignment"
          defaultMessage="Moderate alignment"
        />
      </span>
    );
  }
  // higher
  else {
    // @ts-expect-error
    color = RANGE_COLORS[3];
    // @ts-expect-error
    content = (
      <span>
        <FormattedMessage
          id="app.views.team.team_overview.status.high_alignment"
          defaultMessage="High alignment"
        />
      </span>
    );
  }

  return (
    <DottedItem color={color}>
      <span className="fw-bold">{content}</span>
    </DottedItem>
  );
};

const isWithinRange = (value, range, shouldMatchNegativeOne = false) => {
  if (value === -1 && shouldMatchNegativeOne) {
    return true;
  }

  if (Array.isArray(range)) {
    // this is a min-max value range (inclusive), where undefined
    // means no limit on that end, e.g. [90, undefined] means 90 or above
    return (
      (typeof range[0] === 'undefined' || value >= range[0]) &&
      (typeof range[1] === 'undefined' || value <= range[1])
    );
  } else {
    // range expects exact match
    return value === range;
  }
};

const CellComponent = (props) => {
  return (
    <a
      id={props.id}
      key={1}
      className="text-dark"
      target="_blank"
      rel="noopener noreferrer"
      href={props.url}
      style={{ textDecoration: 'none' }}
    >
      <DottedItem
        width={HEATMAP_CELL_WIDTH}
        height="2.4rem"
        color={props.color}
      >
        {props.value}
      </DottedItem>
    </a>
  );
};

const getVisualValueForRange = (
  value,
  formatFunction = null,
  range,
  url,
  id,
  popoverContent
) => {
  let color = '';

  for (let i = 0; i < range.length; i++) {
    if (isWithinRange(value, range[i], i === 0)) {
      color = RANGE_COLORS[i];
      break;
    }
  }

  if (value === -1) {
    value = (
      <FormattedMessage
        id="app.views.team.team_overview.cell_component.value.never"
        defaultMessage="Never"
      />
    );
  } else if (formatFunction) {
    // @ts-expect-error

    value = formatFunction(value);
  }

  return (
    <>
      <CellComponent id={id} color={color} value={value} url={url} />
      {popoverContent ? (
        <UncontrolledPopover placement="top" trigger="hover" target={id}>
          {popoverContent}
        </UncontrolledPopover>
      ) : undefined}
    </>
  );
};

const getDaysSinceLastFeedbackOrRecognition = (
  person,
  ranges,
  formatMessage,
  formatRelativeTime
) => {
  let value = getDaysSince(person.most_recent_feedback_at);

  if (typeof value === 'undefined') {
    // -1 means "never"
    value = -1;
  }

  return [
    value,
    // @ts-expect-error
    getVisualValueForRange(
      value,
      (value) =>
        formatRelativeTime(-value, 'day', {
          style: 'long',
        }),
      ranges,
      person.url + consts.PROFILE_TAB_FEEDBACK(formatMessage).path
    ),
  ];
};

const getDaysSinceLastObjectiveAccess = (
  person,
  ranges,
  formatMessage,
  formatRelativeTime
) => {
  let value = getDaysSince(person.most_recent_objective_at);

  if (typeof value === 'undefined') {
    // -1 means "never"
    value = -1;
  }

  return [
    value,
    // @ts-expect-error
    getVisualValueForRange(
      value,
      (value) =>
        formatRelativeTime(-value, 'day', {
          style: 'long',
        }),
      ranges,
      person.url + consts.PROFILE_TAB_OBJECTIVES(formatMessage).path
    ),
  ];
};

const getDaysSinceLastAspirationOrGoalAccess = (
  person,
  ranges,
  formatMessage,
  formatRelativeTime
) => {
  // get later of updating aspiration OR goal
  let value = getDaysSince(
    person.most_recent_aspiration_at > person.most_recent_goal_at
      ? person.most_recent_aspiration_at
      : person.most_recent_goal_at
  );

  if (typeof value === 'undefined') {
    // -1 means "never"
    value = -1;
  }

  return [
    value,
    // @ts-expect-error
    getVisualValueForRange(
      value,
      (value) =>
        formatRelativeTime(-value, 'day', {
          style: 'long',
        }),
      ranges,
      person.url + consts.PROFILE_TAB_GOALS_AND_ASPIRATIONS(formatMessage).path
    ),
  ];
};

const getOperatingManualCompletion = (person, ranges, formatMessage) => {
  const value = person.operating_manual_percent_complete
    ? person.operating_manual_percent_complete
    : 0;

  return [
    value,
    // @ts-expect-error
    getVisualValueForRange(
      value,
      (percentValue) =>
        formatMessage(
          {
            id: 'app.views.team.team_overview.percent_done',
            defaultMessage: '{percentValue}% done',
          },
          { percentValue }
        ),
      ranges,
      person.url + consts.PROFILE_TAB_OPERATING_MANUAL(formatMessage).path
    ),
  ];
};

const getLastPulseCheck = (person, ranges) => {
  const value = person.last_pulse_check_rating
    ? person.last_pulse_check_rating
    : -1;

  const popover = person.last_pulse_check_at ? (
    <PulseCheck
      person={person}
      // @ts-expect-error
      pulseCheck={{
        rating: person.last_pulse_check_rating,
        rating_comments: person.last_pulse_check_comments,
        created_at: person.last_pulse_check_at,
        updated_at: person.last_pulse_check_at,
      }}
    />
  ) : undefined;

  return [
    value,
    getVisualValueForRange(
      value,
      // @ts-expect-error
      (value) => value,
      ranges,
      person.url,
      'pulse-' + person.id,
      popover
    ),
  ];
};

const displayRange = (rangeValue) => {
  if (Array.isArray(rangeValue)) {
    return (
      rangeValue[0] +
      (typeof rangeValue[1] === 'undefined' ? '+' : ' - ' + rangeValue[1])
    );
  } else return rangeValue;
};

const getStatusPopover = (
  labelValue,
  numerator,
  denominator,
  ranges,
  formatPopover
) => {
  return (
    <>
      {formatPopover && (
        <Row>
          <Col>
            {formatStatusFromValue(labelValue)}
            <div className="mt-2">
              {formatPopover(displayRange(ranges[labelValue]))}
            </div>
          </Col>
        </Row>
      )}
      {!formatPopover && (
        <Row>
          <Col>{formatStatusFromValue(labelValue)} </Col>
        </Row>
      )}
      <Row className="mt-3">
        <Col>
          <FormattedMessage
            id="app.views.team.team_overview.status.popover"
            defaultMessage="<span>{numerator}</span> of {denominator} people"
            values={{
              numerator,
              denominator,
              span: (chunk) => <span className="fw-bold">{chunk}</span>,
            }}
          />
        </Col>
      </Row>
    </>
  );
};

const getValueFromValuesAndRanges = (
  index,
  values,
  ranges,
  shouldMatchNegativeOne = false
) => {
  const range = ranges[index];
  return values.filter((v) => isWithinRange(v, range, shouldMatchNegativeOne))
    .length;
};

const SummaryCard = (props) => {
  const ratings = useMemo(
    () => [
      {
        labelValue: 0,
        value: getValueFromValuesAndRanges(0, props.values, props.ranges, true),
        color: RANGE_COLORS[0],
      },
      {
        labelValue: 1,
        value: getValueFromValuesAndRanges(1, props.values, props.ranges),
        color: RANGE_COLORS[1],
      },
      {
        labelValue: 2,
        value: getValueFromValuesAndRanges(2, props.values, props.ranges),
        color: RANGE_COLORS[2],
      },
      {
        labelValue: 3,
        value: getValueFromValuesAndRanges(3, props.values, props.ranges),
        color: RANGE_COLORS[3],
      },
    ],
    [props.ranges, props.values]
  );

  const total =
    ratings[0].value + ratings[1].value + ratings[2].value + ratings[3].value;

  const cardBody = (
    <Row className="align-items-center">
      <Col>
        {!props.compressed && props.title && (
          <h6 className="text-uppercase text-muted mb-2">
            <span className={'fe me-2 ' + props.icon}></span>
            {props.title}
          </h6>
        )}
        <Row className="align-items-center no-gutters">
          {props.compressed && props.title && (
            <Col className="col-auto pe-0">
              <h6 className="text-uppercase text-muted mb-0">
                <span className={'fe me-2 ' + props.icon}></span>
                {props.title}
              </h6>
            </Col>
          )}
          <Col className="col">
            <Progress multi>
              {ratings.map((r, index) => (
                <Fragment key={r.labelValue}>
                  <Progress
                    id={props.idPrefix + '-' + index}
                    bar
                    style={{ backgroundColor: r.color }}
                    value={(r.value / total) * 100}
                  />
                  <UncontrolledPopover
                    placement="top"
                    trigger="hover"
                    target={props.idPrefix + '-' + index}
                  >
                    {getStatusPopover(
                      r.labelValue,
                      r.value,
                      total,
                      props.ranges,
                      props.formatPopover
                    )}
                  </UncontrolledPopover>
                </Fragment>
              ))}
            </Progress>
          </Col>
          {props.compressed && props.title && (
            <Col className="col-auto ms-3">
              <h6 className="text-uppercase text-primary mb-0">
                {props.breakdownText}
              </h6>
            </Col>
          )}
        </Row>
      </Col>
    </Row>
  );

  if (props.compressed) {
    return <div className="my-2 mx-4">{cardBody}</div>;
  }

  return <div className="px-2">{cardBody}</div>;
};

const ActionsComponent = (props) => {
  const { formatMessage } = useIntl();
  const isEnabledGivingActionableFeedback = useMemo(
    () => continuousFeedbackGivingFeedbackIsEnabled(props.features),
    [props.features]
  );

  const isEnabledRecognition = useMemo(
    () => continuousFeedbackRecognitionIsEnabled(props.features),
    [props.features]
  );

  const isEnabledPrivateNotes = useMemo(
    () => continuousFeedbackPrivateNotesAreEnabled(props.features),
    [props.features]
  );

  const isEnabledRequestingFeedback = useMemo(
    () => continuousFeedbackRequestsAreEnabled(props.features),
    [props.features]
  );

  return (
    <UncontrolledDropdown>
      <DropdownToggle
        className="btn btn-sm btn-rounded-circle btn-white"
        role="button"
        style={{
          marginLeft: '1.2rem',
        }}
      >
        <i
          className="fe fe-more-horizontal"
          style={{ position: 'relative', top: '2px' }}
        />
      </DropdownToggle>
      <DropdownMenu end>
        <div>
          {isEnabledGivingActionableFeedback && (
            <DropdownItem
              onClick={() =>
                props.setFeedbackPersonAndType([
                  props.person,
                  FEEDBACK_TYPE_ACTIONABLE(formatMessage),
                ])
              }
            >
              <FormattedMessage
                id="app.views.team.team_overview.action_component.give_feedback_to"
                defaultMessage="Give feedback to {givenName}"
                values={{ givenName: props.person.given_name }}
              />
            </DropdownItem>
          )}
          {isEnabledRecognition && (
            <DropdownItem
              onClick={() =>
                props.setFeedbackPersonAndType([
                  props.person,
                  FEEDBACK_TYPE_RECOGNITION(formatMessage),
                ])
              }
            >
              <FormattedMessage
                id="app.views.team.team_overview.action_component.give_recognition_to"
                defaultMessage="Give recognition to {givenName}"
                values={{ givenName: props.person.given_name }}
              />
            </DropdownItem>
          )}
          {isEnabledRequestingFeedback && (
            <DropdownItem
              onClick={() =>
                props.setFeedbackPersonAndType([
                  props.person,
                  FEEDBACK_TYPE_REQUEST(formatMessage),
                ])
              }
            >
              <FormattedMessage
                id="app.views.team.team_overview.action_component.request_feedback_about"
                defaultMessage="Request feedback about {givenName}"
                values={{ givenName: props.person.given_name }}
              />
            </DropdownItem>
          )}
          {isEnabledPrivateNotes && (
            <DropdownItem
              onClick={() =>
                props.setFeedbackPersonAndType([
                  props.person,
                  FEEDBACK_TYPE_NOTE(formatMessage),
                ])
              }
            >
              <FormattedMessage
                id="app.views.team.team_overview.action_component.take_private_notes_about"
                defaultMessage="Take private notes about {givenName}"
                values={{ givenName: props.person.given_name }}
              />
            </DropdownItem>
          )}
        </div>
      </DropdownMenu>
    </UncontrolledDropdown>
  );
};

const TeamOverview: FC<Props> = (props) => {
  const { formatMessage, formatRelativeTime } = useIntl();
  const [people, setPeople] = useState(undefined);
  const [errorMessage, setErrorMessage] = useState(null);
  const [feedbackPersonAndType, setFeedbackPersonAndType] = useState(null);
  const [showStatusBreakdown, setShowStatusBreakdown] = useState(false);
  const [oneOnOneSchedule, setOneOnOneSchedule] = useState(null);
  const toggleStatusBreakdown = useCallback(
    () => setShowStatusBreakdown(!showStatusBreakdown),
    [showStatusBreakdown]
  );

  const { user } = useAuth0();
  const userSub = user?.sub;
  const currentOrgId = props.currentOrganization?.id;

  const features = useMemo(() => props.features || {}, [props.features]);

  const isEnabledPulseChecks = useMemo(
    () => features?.pulse_checks.enabled,
    [features?.pulse_checks.enabled]
  );
  const isEnabledObjectives = useMemo(
    () => features?.objectives.enabled,
    [features?.objectives.enabled]
  );
  const isEnabledAtLeastOneContinuousFeedbackFeature = useMemo(
    () => atLeastOneContinuousFeedbackFeatureIsEnabled(features),
    [features]
  );
  const isEnabledFeedbackOrRecognition = useMemo(
    () =>
      continuousFeedbackGivingFeedbackIsEnabled(features) ||
      continuousFeedbackRecognitionIsEnabled(features),
    [features]
  );
  const isEnabledGoalsAndAspirations = useMemo(
    () => features?.goals.enabled,
    [features?.goals.enabled]
  );
  const isEnabledOperatingManual = useMemo(
    () => features?.operating_manual.enabled,
    [features?.operating_manual.enabled]
  );
  const isEnabledActions = isEnabledAtLeastOneContinuousFeedbackFeature;

  useEffect(() => {
    if (
      typeof people === 'undefined' &&
      props.currentOrganization?.id !== undefined
    ) {
      ConfirmAPI.getUrlWithCache(
        '/get-team-overview',
        'get-team-overview',
        userSub,
        props.currentProxyPerson,
        { organization_id: currentOrgId },
        (data) => {
          if (data?.people) {
            setPeople(data.people);
          } else {
            console.error('get-team-overview returned no people');
          }
        },
        (message) => {
          setErrorMessage(message);
          // @ts-expect-error
          setPeople(null);
        }
      );
    }
  }, [
    userSub,
    people,
    props.currentOrganization?.id,
    props.currentProxyPerson,
    currentOrgId,
  ]);

  useEffect(() => {
    if (
      isEnabled(features, consts.FLAGS.ALLOW_CALENDAR_INTEGRATION) &&
      typeof people !== 'undefined' &&
      // @ts-expect-error
      people?.length > 0 &&
      oneOnOneSchedule === null &&
      props.currentOrganization?.id
    ) {
      getSchedules(
        // @ts-expect-error
        people.map((p) => p.id),
        props.currentOrganization?.id,
        (data) => setOneOnOneSchedule(data)
      );
    }
  }, [people, oneOnOneSchedule, features, props.currentOrganization?.id]);

  const getOverallStatusValue = useCallback(
    (person) => {
      let currentPoints = 0;
      let totalPotentialPoints = 0;

      // factors to consider in calculating overall status
      const factorsList = [
        [isEnabledObjectives, person.objective, OBJECTIVE_RANGES],
        [isEnabledFeedbackOrRecognition, person.feedback, FEEDBACK_RANGES],
        [isEnabledGoalsAndAspirations, person.aspiration, ASPIRATION_RANGES],
        [
          isEnabledOperatingManual,
          person.operating_manual,
          OPERATING_MANUAL_RANGES,
        ],
      ];

      // go through each factor and calculate the status
      for (const factor of factorsList) {
        if (factor[0] && factor[1]?.length > 0) {
          // 0-3 points possible (one less than num buckets)
          totalPotentialPoints += NUM_BUCKETS - 1;

          for (let i = 0; i < factor[2].length; i++) {
            if (isWithinRange(factor[1][0], factor[2][i], i === 0)) {
              currentPoints += i;
              break;
            }
          }
        }
      }

      // 0-24% -> 0, 25-49% -> 1, 50->74% -> 2, 75%+ -> 3
      return Math.floor((currentPoints * NUM_BUCKETS) / totalPotentialPoints);
    },
    [
      isEnabledObjectives,
      isEnabledFeedbackOrRecognition,
      isEnabledGoalsAndAspirations,
      isEnabledOperatingManual,
    ]
  );

  const getOneOnOnesStatus = useCallback(
    (person, schedules) => {
      const DEFAULT_ONE_ON_ONE_STATUS = [
        formatMessage({
          id: 'app.views.team.team_overview.one_o_one_status_default.none',
          defaultMessage: 'None',
        }),
        // @ts-expect-error
        getVisualValueForRange(
          formatMessage({
            id: 'app.views.team.team_overview.one_o_one_status_default.none',
            defaultMessage: 'None',
          }),
          (value) => value,
          ONE_ON_ONE_RANGES,
          person.url + consts.PROFILE_TAB_PERFORMANCE().path
        ),
      ];

      if (!person?.id || !schedules) {
        return DEFAULT_ONE_ON_ONE_STATUS;
      }

      const event = schedules?.[person.id]?.self?.[0];
      if (!event) {
        return DEFAULT_ONE_ON_ONE_STATUS;
      }

      const stringValue = event?.recurrence
        ? capitalize(event.recurrence.toLowerCase())
        : '';
      const summary = event?.recurrenceSummary;

      return [
        stringValue,
        getVisualValueForRange(
          stringValue,
          // @ts-expect-error
          (value) => value,
          ONE_ON_ONE_RANGES,
          person.url + consts.PROFILE_TAB_PERFORMANCE().path,
          'oneOnOne-' + person.id,
          summary
        ),
      ];
    },
    [formatMessage]
  );

  const rows = useMemo(() => {
    return (
      people
        // @ts-expect-error
        ?.map((person) => {
          const p = person;
          const pWithValues = {
            ...p,
            person: p,
            feedback: getDaysSinceLastFeedbackOrRecognition(
              p,
              FEEDBACK_RANGES,
              formatMessage,
              formatRelativeTime
            ),
            objective: getDaysSinceLastObjectiveAccess(
              p,
              OBJECTIVE_RANGES,
              formatMessage,
              formatRelativeTime
            ),
            aspiration: getDaysSinceLastAspirationOrGoalAccess(
              p,
              ASPIRATION_RANGES,
              formatMessage,
              formatRelativeTime
            ),
            operating_manual: getOperatingManualCompletion(
              p,
              OPERATING_MANUAL_RANGES,
              formatMessage
            ),
            one_on_ones: getOneOnOnesStatus(p, oneOnOneSchedule),
            pulse_checks: getLastPulseCheck(p, PULSE_CHECK_RANGES),
            // add field for each person above in chain of command for filtering
            // by a person's full team
            ...(p.chain_of_command_list
              ? p.chain_of_command_list.reduce(
                  (dict, p) => ({
                    ...dict,
                    [BELOW_MANAGER_FILTER_PREFIX + p.id]: p,
                  }),
                  {}
                )
              : {}),
            actions: isEnabledActions
              ? [
                  null,
                  <ActionsComponent
                    key={p.id}
                    person={p}
                    features={props.features}
                    setFeedbackPersonAndType={setFeedbackPersonAndType}
                  />,
                ]
              : undefined,
          };

          return {
            ...pWithValues,
            status: getOverallStatusValue(pWithValues),
          };
        })
        .sort((a, b) => comparePeople(a?.person, b?.person))
    );
  }, [
    people,
    formatMessage,
    oneOnOneSchedule,
    isEnabledActions,
    props.features,
    getOverallStatusValue,
    formatRelativeTime,
    getOneOnOnesStatus,
  ]);

  // show managers list for the "mananger and above" filter functionality
  const managersList = useMemo(() => {
    if (!rows?.length) {
      return [];
    }

    return rows.reduce((arr, row) => {
      return [...arr, ...(row.chain_of_command_list || [])];
    }, []);
  }, [rows]);

  const columns = useMemo(
    () => [
      {
        name: formatMessage({
          id: 'app.views.team.team_overview.columns.person.name',
          defaultMessage: 'Person',
        }),
        field: 'person',
        popoverContent: formatMessage({
          id: 'app.views.team.team_overview.columns.person.popover_content',
          defaultMessage: 'Name, title, and link to resume',
        }),
        isPerson: true,
        format: FORMAT_AVATAR_WITH_TITLE,
        csvName: ['Name', 'Email'],
        csvFormat: (c) => ({
          Name: c.full_name,
          Email: c.email,
        }),
        getFilterDisplayValue: (c) => c.full_name,
        sort: (a, b) => comparePeople(a?.person, b?.person),
      },
      {
        name: formatMessage({
          id: 'app.views.team.team_overview.columns.manager.name',
          defaultMessage: 'Mgr',
        }),
        field: 'manager',
        popoverContent: formatMessage({
          id: 'app.views.team.team_overview.columns.manager.popover_content',
          defaultMessage: 'Manager',
        }),
        isPerson: true,
        format: FORMAT_AVATAR_ONLY,
        csvFormat: (c) => c.email,
        getFilterDisplayValue: (c) => c.full_name,
        nameTransformerFunction: (name) => name + "'s direct reports",
        sort: (a, b) => comparePeople(a?.manager, b?.manager),
      },
      ...(isEnabledPulseChecks
        ? [
            {
              name: (
                <span>
                  <i className={getIconForFieldName('pulse_checks')} />{' '}
                  <FormattedMessage
                    id="app.views.team.team_overview.columns.pulse_checks.name"
                    defaultMessage="Pulse
                "
                  />
                </span>
              ),
              field: 'pulse_checks',
              csvName: formatMessage({
                id: 'app.views.team.team_overview.columns.pulse_check.cvs_name',
                defaultMessage: 'Most recent pulse check rating',
              }),
              hideFromFilters: true,
              popoverContent: formatMessage({
                id: 'app.views.team.team_overview.columns.pulse_check.popover_content',
                defaultMessage: 'Most recent pulse check rating (1-5)',
              }),
              className: 'ps-1',
              style: {
                width: '1rem',
              },
            },
          ]
        : []),
      ...(isEnabledObjectives
        ? [
            {
              name: (
                <span>
                  <i className={getIconForFieldName('objective')} />{' '}
                  <FormattedMessage
                    id="app.views.team.team_overview.columns.objective.name"
                    defaultMessage="Objectives
                "
                  />
                </span>
              ),
              field: 'objective',
              csvName: formatMessage({
                id: 'app.views.team.team_overview.columns.objective.cvs_name',
                defaultMessage: 'Objectives last updated',
              }),
              hideFromFilters: true,
              popoverContent: formatMessage({
                id: 'app.views.team.team_overview.columns.objective.popover_content',
                defaultMessage: 'Days since objectives last updated',
              }),
              className: 'ps-1',
              style: {
                width: '1rem',
              },
            },
          ]
        : []),
      ...(oneOnOneSchedule
        ? [
            {
              name: (
                <span>
                  <i className={getIconForFieldName('one_on_ones')} />{' '}
                  <FormattedMessage
                    id="app.views.team.team_overview.columns.one_on_ones.name"
                    defaultMessage="1-on-1s
                "
                  />
                </span>
              ),
              field: 'one_on_ones',
              csvName: formatMessage({
                id: 'app.views.team.team_overview.columns.one_on_ones.csv_name',
                defaultMessage: '1-on-1 last had',
              }),
              hideFromFilters: true,
              popoverContent: formatMessage({
                id: 'app.views.team.team_overview.columns.one_on_ones.popover_content',
                defaultMessage:
                  'Frequency of one-on-one meetings with manager according to calendar',
              }),
              className: 'ps-1',
              style: {
                width: '1rem',
              },
            },
          ]
        : []),
      ...(isEnabledFeedbackOrRecognition
        ? [
            {
              name: (
                <span>
                  <i className={getIconForFieldName('feedback')} />{' '}
                  <FormattedMessage
                    id="app.views.team.team_overview.columns.feedback.name"
                    defaultMessage="Feedback
                "
                  />
                </span>
              ),
              field: 'feedback',
              csvName: formatMessage({
                id: 'app.views.team.team_overview.columns.feedback.csv_name',
                defaultMessage: 'Feedback last received',
              }),
              hideFromFilters: true,
              popoverContent: formatMessage({
                id: 'app.views.team.team_overview.columns.feedback.popover_content',
                defaultMessage:
                  'Days since feedback or recognition last received',
              }),
              className: 'ps-1',
              style: {
                width: '1rem',
              },
            },
          ]
        : []),
      ...(isEnabledGoalsAndAspirations
        ? [
            {
              name: (
                <span>
                  <i className={getIconForFieldName('aspiration')} />{' '}
                  <FormattedMessage
                    id="app.views.team.team_overview.columns.aspiration.name"
                    defaultMessage="
                  Aspirations
                "
                  />
                </span>
              ),
              field: 'aspiration',
              csvName: formatMessage({
                id: 'app.views.team.team_overview.columns.aspiration.csv_name',
                defaultMessage: 'Days since recognition last received',
              }),
              hideFromFilters: true,
              popoverContent: formatMessage({
                id: 'app.views.team.team_overview.columns.aspiration.popover_content',
                defaultMessage:
                  'Days since since goals or aspirations last updated',
              }),
              className: 'ps-1',
              style: {
                width: '1rem',
              },
            },
          ]
        : []),
      ...(isEnabledOperatingManual
        ? [
            {
              name: (
                <span>
                  <i className={getIconForFieldName('operating_manual')} />{' '}
                  <FormattedMessage
                    id="app.views.team.team_overview.columns.operating_manual.name"
                    defaultMessage="
                  Manual
                "
                  />
                </span>
              ),
              field: 'operating_manual',
              csvName: formatMessage({
                id: 'app.views.team.team_overview.columns.operating_manual.csv_name',
                defaultMessage: 'Operating manual done',
              }),
              hideFromFilters: true,
              popoverContent: formatMessage({
                id: 'app.views.team.team_overview.columns.operating_manual.popover_content',
                defaultMessage: 'Completion of operating manual',
              }),
              className: 'ps-1',
              style: {
                width: '1rem',
              },
            },
          ]
        : []),
      ...(isEnabledActions
        ? [
            {
              name: (
                <span>
                  <i className={consts.ICONS.ACTION} />{' '}
                  <FormattedMessage
                    id="app.views.team.team_overview.columns.actions.name"
                    defaultMessage=" Actions
                "
                  />
                </span>
              ),
              field: 'actions',
              hideFromCSV: true,
              hideFromFilters: true,
              style: {
                width: '5rem',
              },
            },
          ]
        : []),
      // to help with filtering
      {
        filterOnly: true,
        field: 'title',
        name: formatMessage({
          id: 'app.views.team.team_overview.columns.title.name',
          defaultMessage: 'Title',
        }),
        filterIcon: ICONS.TITLE_FILTER,
      },
      {
        filterOnly: true,
        field: 'level_id',
        name: formatMessage({
          id: 'app.views.team.team_overview.columns.level.name',
          defaultMessage: 'Level',
        }),
        filterIcon: ICONS.LEVEL_FILTER,
      },
      {
        filterOnly: true,
        field: 'function',
        name: formatMessage({
          id: 'app.views.team.team_overview.columns.format.name',
          defaultMessage: 'Function',
        }),
        filterIcon: ICONS.FUNCTION_FILTER,
      },
      {
        filterOnly: true,
        field: 'location',
        name: formatMessage({
          id: 'app.views.team.team_overview.columns.location.name',
          defaultMessage: 'Location',
        }),
        filterIcon: ICONS.LOCATION_FILTER,
      },
      {
        filterOnly: true,
        field: 'department',
        name: formatMessage({
          id: 'app.views.team.team_overview.columns.department.name',
          defaultMessage: 'Department',
        }),
        filterIcon: ICONS.DEPARTMENT_FILTER,
      },
      {
        filterOnly: true,
        field: 'business_unit',
        name: formatMessage({
          id: 'app.views.team.team_overview.columns.business_unit.name',
          defaultMessage: 'Business unit',
        }),
        filterIcon: ICONS.DEPARTMENT_FILTER,
      },
      {
        filterOnly: true,
        field: 'job_family',
        name: formatMessage({
          id: 'app.views.team.team_overview.columns.job_family.name',
          defaultMessage: 'Job family',
        }),
        filterIcon: ICONS.FUNCTION_FILTER,
      },
      {
        filterOnly: true,
        field: 'cost_center',
        name: formatMessage({
          id: 'app.views.team.team_overview.columns.cost_center.name',
          defaultMessage: 'Cost center',
        }),
        filterIcon: ICONS.COST_CENTER_FILTER,
      },
      {
        filterOnly: true,
        field: 'cost_center_id',
        name: formatMessage({
          id: 'app.views.team.team_overview.columns.cost_center_id.name',
          defaultMessage: 'Cost center ID',
        }),
        filterIcon: ICONS.COST_CENTER_FILTER,
      },
      // map fields for filtering for each person in the chain of command
      ...managersList.map((p) => ({
        filterOnly: true,
        name: formatMessage(
          {
            id: 'app.views.team.team_overview.columns.manager_name.name',
            defaultMessage: "{personName}'s full team",
          },
          { personName: p.full_name }
        ),
        field: BELOW_MANAGER_FILTER_PREFIX + p.id,
        isPerson: true,
        getFilterDisplayValue: (c) => c.full_name,
        nameTransformerFunction: (name) =>
          formatMessage(
            {
              id: 'app.views.team.team_overview.columns.manager_name.name_transformer_function',
              defaultMessage: "{personName}'s full team",
            },
            { personName: name }
          ),
      })),
    ],
    [
      formatMessage,
      isEnabledPulseChecks,
      isEnabledObjectives,
      oneOnOneSchedule,
      isEnabledFeedbackOrRecognition,
      isEnabledGoalsAndAspirations,
      isEnabledOperatingManual,
      isEnabledActions,
      managersList,
    ]
  );

  const generateH3 = useCallback(
    (chunk) => <h3 className="d-inline-block mb-0">{chunk}</h3>,
    []
  );

  const generateMuted = useCallback(
    (chunk) => <span className="text-muted">{chunk}</span>,
    []
  );

  const formatObjectivesPopover = useCallback(
    (text) => (
      <span>
        <FormattedMessage
          id="app.views.team.team_overview.output.objectives.summary_card.popover"
          defaultMessage="<h3>{text}</h3> days <muted>since objectives last updated</muted>"
          values={{
            text,
            h3: generateH3,
            muted: generateMuted,
          }}
        />
      </span>
    ),
    [generateH3, generateMuted]
  );

  const formatFeedbackPopover = useCallback(
    (text) => (
      <span>
        <FormattedMessage
          id="app.views.team.team_overview.output.feedback.summary_card.popover"
          defaultMessage="<h3>{text}</h3> days <muted> since feedback or recognition last received</muted>"
          values={{
            text,
            h3: generateH3,
            muted: generateMuted,
          }}
        />
      </span>
    ),
    [generateH3, generateMuted]
  );

  const formatAspirationsPopover = useCallback(
    (text) => (
      <span>
        <FormattedMessage
          id="app.views.team.team_overview.output.aspirations.summary_card.popover"
          defaultMessage="<h3>{text}</h3> days <muted>since goals or aspirations last updated</muted>"
          values={{
            text,
            h3: generateH3,
            muted: generateMuted,
          }}
        />
      </span>
    ),
    [generateH3, generateMuted]
  );

  const formatOperatingManualPopover = useCallback(
    (text) => (
      <span>
        <FormattedMessage
          id="app.views.team.team_overview.output.operating_manual.summary_card.popover"
          defaultMessage="<h3>{text}%</h3> <muted>operating manual done</muted>"
          values={{
            text,
            h3: generateH3,
            muted: generateMuted,
          }}
        />
      </span>
    ),
    [generateH3, generateMuted]
  );

  const formatOneOnOnesPopover = useCallback(
    (text) => (
      <span>
        <FormattedMessage
          id="app.views.team.team_overview.output.oneonones.summary_card.popover"
          defaultMessage="<h3>{text}</h3> <muted>frequency of one-on-one meetings with manager</muted>"
          values={{
            text,
            h3: generateH3,
            muted: generateMuted,
          }}
        />
      </span>
    ),
    [generateH3, generateMuted]
  );

  const loadOrRenderOutput = loadOrRender(rows, errorMessage);
  if (loadOrRenderOutput) {
    return loadOrRenderOutput;
  }

  if (rows === undefined) {
    return <Loading />;
  }

  if (!rows?.length) {
    return (
      <Card>
        <CardBody>
          <EmptyState
            title={formatMessage({
              id: 'app.views.team.team_overview.output.empty_state.title',
              defaultMessage: 'No direct reports',
            })}
            subtitle={formatMessage({
              id: 'app.views.team.team_overview.output.empty_state.sub_title',
              defaultMessage:
                'This dashboard is for people managers. If this is not what you expect, contact your HR administrator.',
            })}
          />
        </CardBody>
      </Card>
    );
  }

  return (
    <>
      <ModalFeedbackEditorButton
        toggle={() => setFeedbackPersonAndType(null)}
        isOpen={!!feedbackPersonAndType}
        onClosed={() => setFeedbackPersonAndType(null)}
        hideButton={true}
        callback={() => setFeedbackPersonAndType(null)}
        feedback={
          feedbackPersonAndType
            ? {
                subject_people: [feedbackPersonAndType[0]],
                type: feedbackPersonAndType[1],
              }
            : undefined
        }
      />
      {/* @ts-expect-error */}
      <FilterablePeopleTable
        // TODO: determine if this flag is truly needed here
        arrayValuesUsedForFormatting={true}
        headerContent={
          <div role="button" onClick={toggleStatusBreakdown}>
            <SummaryCard
              compressed={true}
              title={formatMessage({
                id: 'app.views.team.team_overview.output.filterable_table.title',
                defaultMessage: 'Team alignment',
              })}
              icon={ICONS.ACTIVITY}
              breakdownText={
                showStatusBreakdown
                  ? formatMessage({
                      id: 'app.views.team.team_overview.output.filterable_table.breakdown_text.hide_details',
                      defaultMessage: 'Hide details',
                    })
                  : formatMessage({
                      id: 'app.views.team.team_overview.output.filterable_table.breakdown_text.view_details',
                      defaultMessage: 'View details',
                    })
              }
              values={rows.map((row) => row.status)}
              ranges={STATUS_RANGES}
            />
          </div>
        }
        tableClassName="team-overview-table"
        rows={rows}
        columns={columns}
        getUniqueRowId={getId}
      >
        {' '}
        {showStatusBreakdown && (
          <CardBody>
            <Row>
              {isEnabledObjectives && (
                <Col>
                  <SummaryCard
                    idPrefix="objectives"
                    title={formatMessage({
                      id: 'app.views.team.team_overview.output.objectives.title',
                      defaultMessage: 'Objectives',
                    })}
                    icon={ICONS.OBJECTIVE}
                    values={rows.map((row) => row.objective[0])}
                    ranges={OBJECTIVE_RANGES}
                    formatPopover={formatObjectivesPopover}
                  />
                </Col>
              )}
              {isEnabledFeedbackOrRecognition && (
                <Col>
                  <SummaryCard
                    idPrefix="feedback"
                    title={formatMessage({
                      id: 'app.views.team.team_overview.output.feedback.title',
                      defaultMessage: 'Feedback',
                    })}
                    icon={ICONS.FEEDBACK}
                    values={rows.map((row) => row.feedback[0])}
                    ranges={FEEDBACK_RANGES}
                    formatPopover={formatFeedbackPopover}
                  />
                </Col>
              )}
              {isEnabledGoalsAndAspirations && (
                <Col>
                  <SummaryCard
                    idPrefix="aspirations"
                    title={formatMessage({
                      id: 'app.views.team.team_overview.output.aspirations.title',
                      defaultMessage: 'Aspirations',
                    })}
                    icon={ICONS.ASPIRATION}
                    values={rows.map((row) => row.aspiration[0])}
                    ranges={ASPIRATION_RANGES}
                    formatPopover={formatAspirationsPopover}
                  />
                </Col>
              )}
              {isEnabledOperatingManual && (
                <Col>
                  <SummaryCard
                    idPrefix="operating_manual"
                    title={formatMessage({
                      id: 'app.views.team.team_overview.output.operating_manual.title',
                      defaultMessage: 'Operating manual',
                    })}
                    icon={ICONS.OPERATING_MANUAL}
                    values={rows.map((row) => row.operating_manual[0])}
                    ranges={OPERATING_MANUAL_RANGES}
                    formatPopover={formatOperatingManualPopover}
                  />
                </Col>
              )}
              {oneOnOneSchedule && (
                <Col>
                  <SummaryCard
                    idPrefix="oneonones"
                    title={formatMessage({
                      id: 'app.views.team.team_overview.output.oneonones.title',
                      defaultMessage: '1-on-1s',
                    })}
                    icon={ICONS.ONE_ON_ONES}
                    values={rows.map((row) => row.one_on_ones[0])}
                    ranges={ONE_ON_ONE_RANGES}
                    formatPopover={formatOneOnOnesPopover}
                  />
                </Col>
              )}
            </Row>
          </CardBody>
        )}
      </FilterablePeopleTable>
    </>
  );
};

interface Props {
  currentOrganization: Organization;
  currentProxyPerson?: Person;
  features: Features;
  me: Me;
}

const mapStateToProps = (state) => {
  const { features, me, currentOrganization, currentProxyPerson } = state;

  return { features, me, currentOrganization, currentProxyPerson };
};

// @ts-expect-error
export default connect(mapStateToProps)(withRouter(React.memo(TeamOverview)));
