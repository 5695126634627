import { FormattedMessage, useIntl } from 'react-intl';
import {
  GroupField,
  HeaderDescriptor,
  computeAvailableData,
  generateFields,
} from './utils';
import React, { FC, useCallback, useMemo, useState } from 'react';

import { Campaign } from '../../../../../types';
import { ICONS } from '../../../../../consts/consts';
import ModalEditor from '../../../Modals/ModalEditor';
import { createCSVFileName } from '../../../../../utils/util/util';
import { Button } from 'reactstrap';
import { formatForCSV } from './helpers';
import CSVLinkAsync from 'components/CSVLinkAsync';

interface Props {
  data: object[];
  // for additional optional data fields corresponding to
  // the data in the data array by id
  personIdToMetadataMap: Record<number, any> | undefined;
  headers: HeaderDescriptor[];
  campaign: Campaign;
  onDownloadRequested: (groupFields: GroupField[]) => void;
}

const TeamDashboardCSVDownload: FC<Props> = ({
  data,
  personIdToMetadataMap,
  headers,
  campaign,
  onDownloadRequested,
}) => {
  const intl = useIntl();
  const { formatMessage } = intl;
  const [modalIsOpen, setModalIsOpen] = useState<boolean>(false);
  const [isDownloadRequested, setIsDownloadRequested] =
    useState<boolean>(false);
  const [obj, setObj] = useState<object>({});

  const handleClick = useCallback(() => {
    setModalIsOpen(true);
  }, []);

  const handleCallback = useCallback(() => {
    // do nothing as it is done on presubmit
  }, []);

  const handleOnClose = useCallback(() => {
    setIsDownloadRequested(false);
  }, []);

  const handleOnToggle = useCallback(() => {
    setModalIsOpen(false);
  }, []);

  const onChange = useCallback((value) => {
    setObj(value);
  }, []);

  const { filteredHeaders } = useMemo(
    () =>
      computeAvailableData({
        selectedGroupFields: (obj['group_fields'] as GroupField[]) ?? [],
        headers,
        intl,
      }),
    [obj, headers, intl]
  );

  const inputs = useMemo(
    () => generateFields(campaign, headers, intl),
    [campaign, intl, headers]
  );

  const handlePresubmit = useCallback(() => {
    onDownloadRequested(obj['group_fields']);
    setIsDownloadRequested(true);
  }, [obj, onDownloadRequested]);

  const dataGenerator = useMemo(() => {
    if (isDownloadRequested) {
      return () => formatForCSV(data, personIdToMetadataMap, formatMessage);
    }

    return undefined;
  }, [data, personIdToMetadataMap, formatMessage, isDownloadRequested]);

  return (
    <>
      <ModalEditor
        isOpen={modalIsOpen}
        toggle={handleOnToggle}
        title={formatMessage({
          id: 'app.views.widgets.dashboards.team_dashboard.team_dashboard_csv.title',
          defaultMessage: 'Select fields to export',
        })}
        object={obj}
        onChange={onChange}
        preSubmit={handlePresubmit}
        callback={handleCallback}
        onClosed={handleOnClose}
        disabled={isDownloadRequested}
        submitText={formatMessage({
          id: 'app.views.widgets.dashboards.team_dashboard.team_dashboard_csv.submit_text',
          defaultMessage: 'Export',
        })}
        inputs={inputs}
        disableUnsavedChangesPrompt={true}
      />
      <Button
        color="light"
        className="btn btn-light btn-sm"
        onClick={handleClick}
      >
        <i className={ICONS.EXPORT + ' me-2'} />
        <FormattedMessage
          id="app.views.widgets.dashboards.team_dashboard.export_num_to_csv"
          defaultMessage="Export {num} people to CSV"
          values={{
            num: data.length,
          }}
        />
      </Button>
      {filteredHeaders && dataGenerator && (
        <CSVLinkAsync
          getData={dataGenerator}
          headers={filteredHeaders}
          filename={createCSVFileName()}
        />
      )}
    </>
  );
};

export default TeamDashboardCSVDownload;
